const toggleNotificationsContainer = (notificationsContainer, notifications) => {
    if (notifications.length > 0) {
        notificationsContainer.classList.remove('hidden');
    } else {
        notificationsContainer.remove();
    }
};

/* eslint-disable arrow-body-style */
const getNotificationsFromDOM = (notificationsContainer, attribute) => {
    return Array.from(notificationsContainer.querySelectorAll(`[${attribute}]`));
};

const initNotifications = (
    container = document,
    notificationsContainerAttribute = 'data-notifications',
    attribute = 'data-notification',
    closeAttribute = 'data-close-notification'
) => {
    const notificationsContainers = Array.from(container.querySelectorAll(`[${notificationsContainerAttribute}]`));

    notificationsContainers.forEach((notificationsContainer) => {
        let notifications = getNotificationsFromDOM(notificationsContainer, attribute);

        // Remove every notification visitor has already seen
        notifications.forEach((notification) => {
            const id = notification.getAttribute(attribute);

            if (localStorage.getItem(`notification-${id}`)) {
                notification.remove();
            }
        });

        // Refresh notifications with up to date DOM notification elements
        notifications = getNotificationsFromDOM(notificationsContainer, attribute);

        notifications.forEach((notification) => {
            const id = notification.getAttribute(attribute);
            const closeTriggers = [
                ...notification.querySelectorAll('a'),
                ...notification.querySelectorAll(`[${closeAttribute}]`),
            ];

            closeTriggers.forEach((trigger) => {
                trigger.addEventListener('click', (e) => {
                    localStorage.setItem(`notification-${id}`, 'closed');

                    if (trigger.hasAttribute(`${closeAttribute}`)) {
                        e.preventDefault();

                        notification.remove();
                        toggleNotificationsContainer(notificationsContainer, notifications);
                    }
                });
            });
        });

        toggleNotificationsContainer(notificationsContainer, notifications);
    });
};

export { initNotifications as default };
