import _upperFirst from 'lodash/upperFirst';
import { pushToDataLayer, isIE } from './helpers';

/**
 * Async form submission for scaffolded forms in our project skeleton.
 * Register the form fragment element as async by setting up a new
 * AsyncForm instance. e.g. new Async('#contact-form');
 *
 * @param selector
 * @param options
 * @constructor
 */
const AsyncForm = function (container = document, selector, options) {
    // Just let our form submit synchronously for IE users because this browser does not respect our fetch thingy
    if (isIE()) return;

    this.options = {
        response_selector: options.response_selector || '[data-asyncform-response]',
        loading_class: options.loading_class || 'animate-pulse',
        formgroup_selector: options.formgroup_selector || '[data-asyncform-group]',
        error_class: options.error_class || 'with-error',
        onSuccess: options.onSuccess || function () {},
        error_message_selector: options.error_message_selector || '[data-asyncform-error]',
        gtm_disabled: options.gtm_disabled || false,
        gtm_event: options.gtm_event || 'form_submitted',
    };

    this.formContainer = container.querySelector(selector);

    if (!this.formContainer) return;

    this.form = this.formContainer.querySelector('form');
    this.response = this.formContainer.querySelector(this.options.response_selector);

    if (!this.form || !this.response) return;

    this._watchSubmit();
};

AsyncForm.prototype._watchSubmit = function () {
    this.form.addEventListener('validate', (e) => {
        e.preventDefault();

        // Validate form
        this._validate((status, data) => {
            // Reload the page if the session has expired
            if (status === 419) {
                window.location.reload();
                return;
            }

            if (status === 422) {
                // If field keys are passed, only show the errors for those fields
                const filteredErrors = e.detail.fields
                    ? Object.fromEntries(Object.entries(data.errors).filter(([key]) => e.detail.fields.includes(key)))
                    : data.errors;

                this._showErrors(filteredErrors);

                if (e.detail.callback) {
                    e.detail.callback(status, data, filteredErrors);
                }

                return;
            }

            if (e.detail.callback) {
                e.detail.callback(status, data);
            }
        });
    });
    this.form.addEventListener('submit', (e) => {
        e.preventDefault();

        // Show loading state
        this.form.classList.add(this.options.loading_class);

        // Prevent duplicate submissions
        if (this.form.classList.contains('is-submitting')) {
            return;
        }

        // Add class to hook our visual indicator on
        this.form.classList.add('is-submitting');

        // Submit form
        this._submit((status, data) => {
            this.form.classList.remove(this.options.loading_class);
            this.form.classList.remove('is-submitting');

            if (status === 422) {
                this._showErrors(data.errors);
                return;
            }

            // Reload the page if the session has expired
            if (status === 419) {
                window.location.reload();
                return;
            }

            if (status === 201) {
                this._showSuccessResponse();

                // Determine if there needs to push anything to GTM.
                if (!this.options.gtm_disabled) {
                    let eventName = this.options.gtm_event;

                    // The passed event attribute has precedence over the default one.
                    // Remove the event attribute since this is passed separately
                    if (data.datalayer && data.datalayer.event) {
                        eventName = data.datalayer.event;
                        delete data.datalayer.event;
                    }

                    pushToDataLayer(eventName, data.datalayer || {});
                }

                this.options.onSuccess(data);
            }
        });
    });
};

AsyncForm.prototype._submit = function (callback) {
    const body = new FormData(this.form);
    const { method } = this.form;

    fetch(this.form.getAttribute('action'), {
        method,
        body,
        headers: {
            Accept: 'application/json',
        },
    })
        .then((response) => {
            response.json().then((data) => {
                callback(response.status, data);
            });
        })
        .catch((error) => {
            this.form.classList.remove('is-submitting');
            throw new Error(error);
        });
};

AsyncForm.prototype._validate = function (callback) {
    const body = new FormData(this.form);
    const { method } = this.form;

    fetch(this.form.getAttribute('data-validate-action'), {
        method,
        body,
        headers: {
            Accept: 'application/json',
        },
    })
        .then((response) => {
            response.text().then((text) => {
                callback(response.status, text ? JSON.parse(text) : {});
            });
        })
        .catch((error) => {
            throw new Error(error);
        });
};

AsyncForm.prototype._showErrors = function (errors) {
    const fieldKeys = Object.keys(errors);

    // Set all errored fields on the form
    this.form.setAttribute('data-asyncform-errorfields', fieldKeys.join(','));

    // Reset all errors
    this.form.querySelectorAll(this.options.formgroup_selector).forEach((formgroup) => {
        if (fieldKeys.includes(formgroup.getAttribute('data-asyncform-rule'))) {
            this._showFormgroupError(formgroup, errors[formgroup.getAttribute('data-asyncform-rule')]);
        } else {
            this._resetFormgroupError(formgroup);
        }
    });
};

AsyncForm.prototype._showFormgroupError = function (formgroup, errorMessage) {
    const field = formgroup.querySelector('[name]');
    const error = formgroup.querySelector(this.options.error_message_selector);

    if (field) {
        field.classList.add(...this.options.error_class.split(' '));
    }

    if (error) {
        error.style.removeProperty('display');
        error.innerText = _upperFirst(errorMessage);
    }
};

AsyncForm.prototype._resetFormgroupError = function (formgroup) {
    const field = formgroup.querySelector('[name]');
    const error = formgroup.querySelector(this.options.error_message_selector);

    if (field) {
        field.classList.remove(...this.options.error_class.split(' '));
    }

    if (error) {
        error.style.display = 'none';
    }
};

AsyncForm.prototype._showSuccessResponse = function () {
    this.response.classList.remove('hidden');
    this.form.classList.add('hidden');
    this.formContainer.scrollIntoView({ behavior: 'smooth' });
};

export { AsyncForm as default };
