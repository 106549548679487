import ScrollSnapControls from '@thinktomorrow/scroll-snap-controls';

const initCarousels = (containerSelector = '[data-ssc]') => {
    const containers = Array.from(document.querySelectorAll(containerSelector));

    containers.forEach((container) => {
        const carouselScrollSnapControls = new ScrollSnapControls(container);

        carouselScrollSnapControls.init();
    });
};

export default initCarousels;
