import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const initCounter = (container = document, selector = '[data-counter]') => {
    const elements = Array.from(container.querySelectorAll(selector));

    elements.forEach((element) => {
        gsap.from(element, {
            innerHTML: 0,
            roundProps: 'innerHTML',
            duration: 2,
            ease: 'Circ.easeOut',
            scrollTrigger: {
                trigger: element,
                start: 'top bottom',
            },
        });
    });
};

export { initCounter as default };
